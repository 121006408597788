import {
  useCreate,
  useInvalidate,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { Button, Col, Form, Modal, Radio, Row, message } from "antd";
import { useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";
import { getSectionsConfig } from "./sections/sectionsConfig";

type Props = {
  onSuccess?: (data: any) => void;
  onClose?: () => void;
  open?: boolean;
  disableType?: string;
};
const CreateContactModal = ({
  onSuccess,
  open,
  onClose,
  disableType,
}: Props) => {
  const [form] = Form.useForm();
  const { list } = useNavigation();
  const { mutateAsync: createMutate } = useCreate({
    successNotification: false,
    invalidates: ["resourceAll"],
  });
  const { mutateAsync: updateContact } = useUpdate({
    successNotification: false,
    invalidates: ["resourceAll"],
    mutationMode: "pessimistic",
  });
  const invalidate = useInvalidate();
  const { currentTeam } = useUser();
  const [contactType, setContactType] = useState("person");
  const [dynamicSections, setDynamicSections] = useState<string[]>([]);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const translate = useTranslate();

  const sectionsConfig = getSectionsConfig(translate, form);

  const generateCustomerNumber = async () => {
    if (!currentTeam?.account_id) return;
    try {
      const { data, error } = await supabaseClient.rpc(
        "get_next_customer_number",
        { account_id: currentTeam.account_id }
      );

      if (error) {
        console.error("Error generating customer number:", error);
        return;
      }

      form.setFieldsValue({ customer_number: data });
    } catch (error) {
      console.error("Error generating customer number:", error);
    }
  };

  const handleAddSection = (sectionKey: string) => {
    if (!dynamicSections.includes(sectionKey)) {
      setDynamicSections((prev) => [...prev, sectionKey]);
      const sectionConfig = sectionsConfig.find(
        ({ key }) => key === sectionKey
      );
      if (sectionConfig?.component) {
        setTimeout(() => {
          const inputElement = document.querySelector(
            `input#${sectionKey}, textarea#${sectionKey}, select#${sectionKey}`
          );
          if (inputElement) {
            (inputElement as HTMLElement).focus();
          }
        }, 0);
      }
    }
  };

  const groupSectionsByRow = (sections: typeof sectionsConfig) => {
    const rows: Array<typeof sections> = [];
    let currentRow: typeof sections = [];
    let currentSpan = 0;

    sections.forEach((section) => {
      if (currentSpan + section.span > 24) {
        rows.push(currentRow);
        currentRow = [];
        currentSpan = 0;
      }
      currentRow.push(section);
      currentSpan += section.span;
    });

    if (currentRow.length > 0) {
      rows.push(currentRow);
    }

    return rows;
  };

  const renderSections = () => {
    const allSections = [
      ...sectionsConfig.filter(
        ({ required, types }) => required && types.includes(contactType)
      ),
      ...(dynamicSections
        .map((sectionKey) =>
          sectionsConfig.find(({ key }) => key === sectionKey)
        )
        .filter(Boolean) as typeof sectionsConfig),
    ];

    const groupedSections = groupSectionsByRow(allSections);

    return groupedSections.map((row, index) => (
      <Row key={index} gutter={[12, 0]} style={{ marginBottom: 0 }}>
        {row.map(({ key, component: Component, span, props = {} }) => (
          <Col key={key} span={span}>
            <Form.Item name={key} layout="vertical" style={{ marginBottom: 8 }}>
              <Component
                generateCustomerNumber={generateCustomerNumber}
                {...props}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    ));
  };

  const handleSave = async (values: any, closeAfterSave: boolean) => {
    setSaveButtonLoading(true);

    try {
      await form.validateFields();
    } catch (error) {
      setSaveButtonLoading(false);
      return;
    }
    try {
      if (!currentTeam?.account_id) {
        throw new Error("Missing account information.");
      }

      const { phone_number, phone_label, contact, ...contactValues } = values;

      const mainContact = await createMutate({
        resource: "contacts",
        values: {
          ...contactValues,
          type: contactType,
          account: currentTeam.account_id,
        },
      });
      if (phone_number) {
        await createMutate({
          resource: "phone_numbers",
          values: {
            contact: mainContact.data.id,
            number: phone_number,
            label: phone_label,
            account: currentTeam.account_id,
          },
        });
      }
      if (values.contact && mainContact) {
        await updateContact({
          resource: "contacts",
          id: values.contact,
          values: {
            company_id: mainContact.data.id,
          },
        });
      }
      message.success(translate("contacts.create_new_contact_success"));

      if (closeAfterSave && !onSuccess) {
        list("contacts", "replace");
      } else {
        form.resetFields();
        form.setFieldsValue({ type: contactType });
        setDynamicSections([]);
        if (onSuccess) onSuccess(mainContact.data);
      }
    } catch (error) {
      console.error("Error saving contact:", error);
      message.error(translate("contacts.create_new_contact_failed"));
    } finally {
      setSaveButtonLoading(false);
    }
  };

  return (
    <Form form={form} onFinish={(values) => handleSave(values, true)}>
      <Modal
        open={open == undefined ? true : open}
        title={translate("contacts.create_new_contact")}
        onCancel={() => (onClose ? onClose() : list("contacts", "replace"))}
        width={760}
        footer={[
          <Button
            key="cancel"
            onClick={() => (onClose ? onClose() : list("contacts", "replace"))}
            style={{ float: "left" }}
          >
            {translate("buttons.cancel")}
          </Button>,
          <Button
            key="save-and-new"
            type="default"
            loading={saveButtonLoading}
            onClick={() => handleSave(form.getFieldsValue(), false)}
          >
            {translate("buttons.save_and_new")}
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={saveButtonLoading}
            onClick={() => handleSave(form.getFieldsValue(), true)}
          >
            {translate("buttons.save")}
          </Button>,
        ]}
      >
        <Form.Item name="type" initialValue="person" layout="vertical">
          <Radio.Group
            value={contactType}
            onChange={(e) => setContactType(e.target.value)}
            optionType="default"
            buttonStyle="solid"
          >
            {disableType != "person" && (
              <Radio.Button value="person">
                {translate("contacts.segmented.person")}
              </Radio.Button>
            )}
            {disableType != "company" && (
              <Radio.Button value="company">
                {translate("contacts.segmented.company")}
              </Radio.Button>
            )}
          </Radio.Group>
        </Form.Item>

        {renderSections()}

        <Row gutter={[12, 0]} style={{ marginBottom: 0 }}>
          {sectionsConfig
            .filter(
              ({ required, types }) => !required && types.includes(contactType)
            )
            .map(({ key, title }) => (
              <Button
                key={key}
                type="link"
                onClick={() => handleAddSection(key)}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.color = dynamicSections.includes(key)
                    ? "#a6a6a6"
                    : "#404040")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.color = dynamicSections.includes(key)
                    ? "#bfbfbf"
                    : "#595959")
                }
                style={{
                  color: dynamicSections.includes(key) ? "#bfbfbf" : "#595959",
                  fontWeight: dynamicSections.includes(key) ? "500" : "600",
                  transition: "color 0.3s ease",
                }}
              >
                {title}
              </Button>
            ))}
        </Row>
      </Modal>
    </Form>
  );
};

export default CreateContactModal;
